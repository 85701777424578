<template>
  <div class="banners-wrapper" :class="classes">
    <StSlider
      :items-gap="gap"
      disable-arrows
      class="slider"
      data-t="st-m-banners"
    >
      <template v-if="isLoading">
        <StSliderItem
          v-for="i in 4"
          :key="i"
          class="item"
          :style="{
            '--index-modificator': i,
          }"
        >
          <StSkeletonLoader
            :width="skeletonSize"
            :height="skeletonSize"
            bg-color="var(--background-primary)"
            :shimmer-opacity="0.28"
            radius="var(--border-radius-150)"
          />
        </StSliderItem>
      </template>
      <template v-else>
        <div
          v-if="!isEmptyPreBannerSlot"
          class="item"
          :style="{
            '--index-modificator': 0,
          }"
        >
          <slot name="preBanner" />
        </div>
        <StSliderItem
          v-for="(item, index) in items"
          :key="index"
          class="item"
          :style="{
            '--index-modificator': isEmptyPreBannerSlot ? index : index + 1,
          }"
        >
          <StMBanner :item="item" :is-mini="props.isMini" :size="props.size" />
        </StSliderItem>
      </template>
    </StSlider>
  </div>
</template>

<script setup lang="ts">
import StMBanner from './StMBanner.vue'

const props = withDefaults(
  defineProps<{
    isMini?: boolean
    items?: {
      url: string
      title: string | null
      publicUploadUrl: string
    }[]
    isLoading?: boolean
    size?: 's' | 'm' | 'l'
    gap?: number
    padding?: number
  }>(),
  {
    items: () => [],
    size: 's',
    gap: 6,
    padding: 16,
  },
)

const slots = useSlots()
const isEmptyPreBannerSlot = computed(() => !slots.preBanner)
const paddingInPx = computed(() => `${props.padding}px`)
const skeletonSize = computed(
  () =>
    ({
      s: '74px',
      m: '80px',
      l: '90px',
    })[props.size] ?? '90px',
)

/*
  при уменьшении карточек остается пустое место для скрола
  нужно его ограничивать
*/
const maxItemsWidthInCompact = computed(() => {
  const itemsLength =
    props.items?.length ?? 0 + (isEmptyPreBannerSlot.value ? 0 : 1)
  if (!itemsLength) return 0

  const bannerWidth = 52
  const gap = 8

  return `${bannerWidth * itemsLength + gap * itemsLength}px`
})

const classes = computed(() => [{ mini: props.isMini }, props.size])
</script>

<style scoped>
.item {
  pointer-events: all;
  transform-origin: top left;
  transition: transform 0.3s;

  &:first-child {
    margin-left: v-bind(paddingInPx);
  }

  &:last-child {
    margin-right: v-bind(paddingInPx);
  }
}

.banners-wrapper {
  transition: transform 0.3s;

  &.mini {
    transform: translateY(-4px);

    .item {
      transform: scale(0.7) translateX(calc(-34px * var(--index-modificator))); /* stylelint-disable-line */
    }

    & :deep(.st-slider-items) {
      width: v-bind(maxItemsWidthInCompact);
    }
  }
}

.slider {
  background-color: transparent;
}
</style>
